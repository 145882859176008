import { HttpErrorResponse } from '@angular/common/http';
import { Meta, RequestOptions } from '@gridscale/gsclient-js';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import _, { each } from 'lodash';
import * as UserActions from './user.actions';

export interface UserResponse {
  user: User;
}

export interface UserRelationContract {
  contract_uuid: string;
  user_role?: string;
  create_time?: string;
  change_time?: string;
  contract_name?: string;
  locked?: boolean;
  default_project_uuid?: string;
  default_project_name?: string;
}

export interface UserRelationPartner {
  partner_uuid: string;
  user_role: string;
  create_time?: string;
  change_time?: string;
}

export interface UserUpdate {
  first_name?: string;
  last_name?: string;
  language?: string;
  default_project_uuid?: string;
}

export interface User {
  object_uuid: string;
  user_uuid?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  create_time?: string;
  change_time?: string;
  validated?: boolean;
  locked?: false;
  language?: string;
  user_role?: string;
  partner_role?: string;
  twofa_active?: boolean;
  related_contracts?: number;
  favorite?: boolean;
  relations?: {
    contracts?: UserRelationContract[];
    partners?: UserRelationPartner[];
  };
}

export interface UserUpdate {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  language?: string;
}

export const featureKey = 'user';

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (a => a.object_uuid!)!, // define "primary id"
  sortComparer: false
});

export const listAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: a => a.user_uuid!, // define "primary id"
  sortComparer: false
});

const listAdapterInitialState = listAdapter.getInitialState({
  listConfig: {
    limit: 10,
    sort: '-email',
    fields: ['email', 'first_name', 'last_name']
  },
  loaded: false,
  loading: false,
  loadingNext: false
}) as ListState;

export interface ListState extends EntityState<User> {
  listConfig: RequestOptions;
  loaded: boolean;
  loading: boolean;
  loadingNext: boolean;
  meta?: Meta;
  links?: User;
  error?: Error | HttpErrorResponse | undefined;
}

export interface State extends EntityState<User> {
  loading: { [key: string]: boolean };
  loaded: { [key: string]: boolean };
  patching: { [key: string]: boolean };
  deleting: { [key: string]: boolean };
  error?: { [key: string]: Error | HttpErrorResponse | undefined };
  list: ListState;
  current: {
    loading: boolean;
    loaded: boolean;
    patching: boolean;
    error?: Error | HttpErrorResponse | undefined;
    data?: User;
  };
  passwords: {
    loading: boolean;
    loaded: boolean;
    patching: boolean;
    error: Error | HttpErrorResponse | undefined;
    data?: any;
  };
  invites: {
    loading: boolean;
    loaded: boolean;
    patching: boolean;
    error: Error | HttpErrorResponse | undefined;
    data?: any;
  };
  otptokens: {
    loading: boolean;
    loaded: boolean;
    creating: boolean;
    validating: boolean;
    error: Error | HttpErrorResponse | undefined;
    data: {
      loading: { [key: string]: boolean };
      loaded: { [key: string]: boolean };
      patching: { [key: string]: boolean };
      deleting: { [key: string]: boolean };
      data: { [key: string]: any };
    };
  };
  samls: {
    loading: boolean;
    loaded: boolean;
    error: Error | HttpErrorResponse | undefined;
    configs: any;
    data: {
      loading: { [key: string]: boolean };
      loaded: { [key: string]: boolean };
      patching: { [key: string]: boolean };
      data: { [key: string]: any };
    };
  };
  ui_states: {
    resending: boolean;
    resending_error: Error | HttpErrorResponse | undefined | boolean;
  };
  auto_login_settings: {
    auto_login_active: boolean;
    auto_login_options: any[];
    selected_auto_login_option_uuid: string | null;
    user_uuid: string | null;
    loading: boolean;
    loaded: boolean;
    patching: boolean;
    uuid_to_store_later: string | null;
  };
}
export const initialState: State = adapter.getInitialState({
  loading: {},
  loaded: {},
  patching: {},
  deleting: {},
  list: listAdapterInitialState,
  current: {
    loading: false,
    loaded: false,
    patching: false,
    error: undefined,
    data: undefined
  },
  passwords: {
    loading: false,
    loaded: false,
    patching: false,
    error: undefined,
    data: undefined
  },
  invites: {
    loading: false,
    loaded: false,
    patching: false,
    error: undefined,
    data: undefined
  },
  otptokens: {
    loaded: false,
    loading: false,
    creating: false,
    validating: false,
    error: undefined,
    data: {
      loaded: {},
      loading: {},
      patching: {},
      deleting: {},
      data: {}
    }
  },
  samls: {
    loading: false,
    loaded: false,
    error: undefined,
    configs: undefined,
    data: {
      loaded: {},
      loading: {},
      patching: {},
      data: {}
    }
  },
  ui_states: {
    resending: false,
    resending_error: false
  },
  auto_login_settings: {
    auto_login_active: false,
    auto_login_options: [],
    selected_auto_login_option_uuid: null,
    user_uuid: null,
    loading: false,
    loaded: false,
    patching: false,
    uuid_to_store_later: null
  }
});

export const reducer = createReducer(
  initialState,

  on(UserActions.setListConfig, (state, action) => {
    if (_.isEqual(_.get(state, 'list.listConfig', {}), action.config)) {
      return state;
    }
    return {
      // update list config / empty collection
      ...state,

      list: listAdapter.removeAll({
        ...state.list,
        loaded: false,
        listConfig: {
          // ...state.listConfig,
          ...action.config
        }
      })
    };
  }),

  on(UserActions.loadUsers, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: true
    }
  })),

  on(UserActions.loadUsersSuccess, (state: State, action: any) => {
    //TODO: Inject object_uuid to work with generic components!
    const dat = JSON.parse(JSON.stringify(action.data));
    each(dat, _user => {
      _user.object_uuid = _user.user_uuid;
    });
    let nState = {
      // set new entities (clears collection)
      ...state,

      list: listAdapter.setAll(dat, {
        ...state.list,
        meta: action.meta,
        links: {} as any,
        loading: false,
        loaded: true,
        error: undefined
      })
    };

    // Reset Loading State when we iniated the call for a single user, but did all users (because of access rights)
    if (action.uuid) {
      nState = {
        ...nState,
        error: { ...state.error, [action.uuid]: undefined },
        loading: { ...state.loading, [action.uuid]: false },
        loaded: { ...state.loaded, [action.uuid]: true }
      };
    }

    return nState;
  }),

  on(UserActions.loadUsersFailure, (state, action) => {
    let nState = {
      ...state,
      list: listAdapter.removeAll({
        ...state.list,
        loading: false,
        loaded: true,
        error: action.error
      })
    };

    if (action.uuid) {
      nState = {
        ...nState,
        error: { ...state.error, [action.uuid]: undefined },
        loading: { ...state.loading, [action.uuid]: false },
        loaded: { ...state.loaded, [action.uuid]: true }
      };
    }

    return nState;
  }),

  on(UserActions.loadUser, (state, action) => ({
    ...state,
    loading: { ...state.loading, [action.uuid]: true },
    loaded: { ...state.loaded, [action.uuid]: false }
  })),

  on(UserActions.loadUserSuccess, (state, action) => {
    return adapter.setOne(action.data, {
      ...state,
      error: { ...state.error, [action.uuid]: undefined },
      loading: { ...state.loading, [action.uuid]: false },
      loaded: { ...state.loaded, [action.uuid]: true }
    });
  }),

  on(UserActions.loadUserFailure, (state, action) => ({
    ...state,

    error: { ...state.error, [action.uuid]: action.error },
    loading: { ...state.loading, [action.uuid]: false },
    loaded: { ...state.loaded, [action.uuid]: true }
  })),

  // Patch
  on(UserActions.patchUser, (state, action) => ({
    ...state,
    patching: { ...state.patching, [action.uuid]: true },
    error: { ...state.error, [action.uuid]: undefined }
  })),

  on(UserActions.patchUserSuccess, (state, action) => ({
    ...state,

    patching: { ...state.patching, [action.uuid]: false },
    error: { ...state.error, [action.uuid]: undefined }
  })),
  on(UserActions.patchUserFailure, (state, action) => ({
    ...state,

    patching: { ...state.patching, [action.uuid]: false },
    error: { ...state.error, [action.uuid]: action.error }
  })),

  // Resend
  on(UserActions.resend, (state, action) => ({
    ...state,
    ui_states: {
      ...state.ui_states,
      resending: true,
      resending_error: false
    }
  })),

  on(UserActions.resendSuccess, (state, action) => ({
    ...state,
    ui_states: {
      ...state.ui_states,
      resending: false,
      resending_error: false
    }
  })),
  on(UserActions.resendFailure, (state, action) => ({
    ...state,
    ui_states: {
      ...state.ui_states,
      resending: false,
      resending_error: action.error
    }
  })),

  on(UserActions.patchCurrentUser, (state, action) => ({
    ...state,
    current: {
      ...state.current!,
      error: undefined,
      patching: true
    }
  })),

  on(UserActions.patchCurrentUserSuccess, (state, action) => ({
    ...state,
    current: {
      ...state.current!,
      error: undefined,
      patching: false
    }
  })),

  on(UserActions.patchCurrentUserFailure, (state, action) => ({
    ...state,
    current: {
      ...state.current!,
      error: action.error,
      patching: false
    }
  })),

  /********************************
   *          Password             *
   ********************************/
  on(UserActions.loadPasswords, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      error: undefined,
      loading: true
    }
  })),

  on(UserActions.loadPasswordsSuccess, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      error: undefined,
      loading: false,
      loaded: true,
      data: action.data
    }
  })),

  on(UserActions.loadPasswordsFailure, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      error: action.error,
      loading: false
    }
  })),

  on(UserActions.patchPasswords, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      patching: true
    }
  })),

  on(UserActions.patchPasswordsSuccess, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      patching: false,
      error: undefined
    }
  })),

  on(UserActions.patchPasswordsFailure, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      patching: false,
      error: action.error
    }
  })),

  on(UserActions.postPassword, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      error: undefined,
      patching: true
    }
  })),

  on(UserActions.postPasswordSuccess, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      patching: false,
      error: undefined
    }
  })),

  on(UserActions.postPasswordFailure, (state, action) => ({
    ...state,
    passwords: {
      ...state.passwords,
      patching: false,
      error: action.error
    }
  })),

  /********************************
   *             OTP              *
   ********************************/
  on(UserActions.loadOtps, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      error: undefined,
      loading: true
    }
  })),

  on(UserActions.loadOtpsSuccess, (state, action) => {
    const keyedData = _.keyBy(action.data, 'object_uuid');
    return {
      ...state,
      otptokens: {
        ...state.otptokens,
        error: undefined,
        loading: false,
        loaded: true,
        data: {
          ...state.otptokens.data,
          data: keyedData,
          loaded: _.mapValues(keyedData, () => true),
          loading: _.mapValues(keyedData, () => false),
          deleting: _.mapValues(keyedData, () => false)
        }
      }
    };
  }),

  on(UserActions.loadOtpsFailure, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      error: action.error,
      loading: false
    }
  })),

  on(UserActions.createOTP, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      creating: true
    }
  })),

  on(UserActions.createOTPSuccess, (state, action) => {
    const newUUID = action.data.object_uuid;
    return {
      ...state,
      otptokens: {
        ...state.otptokens,
        creating: false,
        error: undefined,
        data: {
          ...state.otptokens.data,
          loaded: {
            ...state.otptokens.data.loaded,
            [newUUID]: true
          },
          loading: {
            ...state.otptokens.data.loading,
            [newUUID]: false
          },
          data: {
            ...state.otptokens.data.data,
            [newUUID]: action.data
          }
        }
      }
    };
  }),

  on(UserActions.createOTPFailure, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      creating: false,
      error: action.error
    }
  })),

  on(UserActions.validateOTP, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      validating: true,
      error: undefined
    }
  })),

  on(UserActions.validateOTPSuccess, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      validating: false,
      error: undefined
    }
  })),

  on(UserActions.validateOTPFailure, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      validating: false,
      error: undefined
    }
  })),

  on(UserActions.deleteOTP, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      error: undefined,
      data: {
        ...state.otptokens.data,
        deleting: {
          ...state.otptokens.data.deleting,
          [action.token_uuid]: true
        }
      }
    }
  })),

  on(UserActions.deleteOTPSuccess, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      error: undefined,
      data: {
        ...state.otptokens.data,
        deleting: {
          ...state.otptokens.data.deleting,
          [action.token_uuid]: true // We keep that on true and wait for the loadOTP to clear them all
        }
      }
    }
  })),

  on(UserActions.deleteOTPailure, (state, action) => ({
    ...state,
    otptokens: {
      ...state.otptokens,
      error: action.error,
      data: {
        ...state.otptokens.data,
        deleting: {
          ...state.otptokens.data.deleting,
          [action.token_uuid]: false
        }
      }
    }
  })),

  /********************************
   *             SAML              *
   ********************************/
  on(UserActions.setSamlFromConfig, (state, action) => {
    const keyedData = _.keyBy(action.data, 'object_uuid');

    return {
      ...state,
      samls: {
        ...state.samls,
        configs: keyedData
      }
    };
  }),

  on(UserActions.loadSamls, (state, action) => ({
    ...state,
    samls: {
      ...state.samls,
      error: undefined,
      loading: true
    }
  })),

  on(UserActions.loadSamlsSuccess, (state, action) => {
    const keyedData = _.keyBy(action.data, 'saml_uuid');
    return {
      ...state,
      samls: {
        ...state.samls,
        error: undefined,
        loading: false,
        loaded: true,
        data: {
          ...state.samls.data,
          data: keyedData,
          loaded: _.mapValues(keyedData, () => true),
          loading: _.mapValues(keyedData, () => false)
        }
      }
    };
  }),

  on(UserActions.loadSamlsFailure, (state, action) => ({
    ...state,
    samls: {
      ...state.samls,
      error: action.error,
      loading: false
    }
  })),

  on(UserActions.activateSaml, (state, action) => {
    return {
      ...state,
      samls: {
        ...state.samls,
        error: undefined,
        data: {
          ...state.samls.data,
          loading: {
            ...state.samls.data.loading,
            [action.data.saml_uuid]: true
          }
        }
      }
    };
  }),

  on(UserActions.deactivateSaml, (state, action) => {
    return {
      ...state,
      samls: {
        ...state.samls,
        error: undefined,
        data: {
          ...state.samls.data,
          loading: {
            ...state.samls.data.loading,
            [action.saml_uuid]: true
          }
        }
      }
    };
  }),

  on(UserActions.updateSamlSuccess, (state, action) => {
    return {
      ...state,
      samls: {
        ...state.samls,
        error: undefined,
        data: {
          ...state.samls.data,
          loading: {
            ...state.samls.data.loading,
            [action.saml_uuid]: false
          }
        }
      }
    };
  }),

  on(UserActions.updateSamlFailure, (state, action) => {
    return {
      ...state,
      samls: {
        ...state.samls,
        error: action.error,
        data: {
          ...state.samls.data,
          loading: {
            ...state.samls.data.loading,
            [action.saml_uuid]: false
          }
        }
      }
    };
  }),

  /********************************
   *             INVITE              *
   ********************************/

  on(UserActions.loadInvites, (state, action) => ({
    ...state,
    invites: {
      ...state.invites,
      error: undefined,
      loading: true
    }
  })),

  on(UserActions.loadInvitesSuccess, (state, action) => {
    const keyedData = _.keyBy(action.data, 'object_uuid');
    return {
      ...state,
      invites: {
        ...state.invites,
        error: undefined,
        loading: false,
        loaded: true,
        data: {
          ...state.invites.data,
          data: keyedData,
          loaded: _.mapValues(keyedData, () => true),
          loading: _.mapValues(keyedData, () => false)
        }
      }
    };
  }),

  on(UserActions.loadInvitesFailure, (state, action) => ({
    ...state,
    invites: {
      ...state.invites,
      error: action.error,
      loading: false
    }
  })),

  /********************************
   *      Autologin Settings      *
   ********************************/

  on(UserActions.getAutoLoginSettings, (state, action) => ({
    ...state,
    auto_login_settings: {
      ...state.auto_login_settings,
      loading: true
    }
  })),

  on(UserActions.getAutoLoginSettingsSuccess, (state, action) => ({
    ...state,
    auto_login_settings: {
      ...state.auto_login_settings,
      ...action.data,
      loading: false,
      loaded: true
    }
  })),

  on(UserActions.getAutoLoginSettingsFailure, (state, action) => ({
    ...state,
    auto_login_settings: {
      ...state.auto_login_settings,
      loading: false,
      loaded: false
    }
  })),

  on(UserActions.patchAutoLoginSettings, (state, action) => ({
    ...state,
    auto_login_settings: {
      ...state.auto_login_settings,
      patching: true
    }
  })),

  on(UserActions.patchAutoLoginSettingsSuccess, (state, action) => ({
    ...state,
    auto_login_settings: {
      ...state.auto_login_settings,
      ...action.data,
      patching: false,
      uuid_to_store_later: null
    }
  })),

  on(UserActions.patchAutoLoginSettingsFailure, (state, action) => ({
    ...state,
    auto_login_settings: {
      ...state.auto_login_settings,
      patching: false
    }
  })),

  on(UserActions.storeAutoLoginSettings, (state, action) => ({
    ...state,
    auto_login_settings: {
      ...state.auto_login_settings,
      uuid_to_store_later: action.uuid
    }
  }))
);
