import { RequestOptions } from '@gridscale/gsclient-js';
import { createAction, props } from '@ngrx/store';
import { User, UserUpdate } from './user.reducer';
export const actionPrefix = '[User]';

/********************************
 *          LIST ACTIONS        *
 ********************************/

export const setListConfig = createAction(actionPrefix + ' Set User List Config', props<{ config: RequestOptions }>());

export const loadUsers = createAction(actionPrefix + ' Load Users');

export const loadUsersNext = createAction(actionPrefix + ' Load Users Next');

export const loadUsersSuccess = createAction(actionPrefix + ' Load Users Success', props<{ data: User[]; uuid?: string }>());

export const loadUsersFailure = createAction(actionPrefix + ' Load Users Failure', props<{ error: Error; uuid?: string }>());

/********************************
 *          LIST ACTIONS        *
 ********************************/

export const loadUser = createAction(actionPrefix + ' Load User', props<{ uuid: string }>());

export const loadUserSuccess = createAction(actionPrefix + ' Load User Success', props<{ uuid: string; data: User }>());

export const loadUserFailure = createAction(actionPrefix + ' Load User Failure', props<{ uuid: string; error: any }>());

export const loadCurrentUser = createAction(actionPrefix + ' Load current User');

// Remove the current Stuff!

/********************************
 *          PATCH ACTIONS        *
 ********************************/

export const patchUser = createAction(actionPrefix + ' Patch User', props<{ uuid: string; data: UserUpdate; fromBatch?: boolean }>());

export const patchUserSuccess = createAction(actionPrefix + ' Patch User Success', props<{ uuid: string }>());

export const patchUserFailure = createAction(actionPrefix + ' Patch User Failure', props<{ error?: Error; uuid: string }>());

/********************************
 *          PATCH ACTIONS        *
 ********************************/

export const deleteUser = createAction(actionPrefix + ' Delete User', props<{ uuid: string; fromBatch?: boolean }>());

export const deleteUserSuccess = createAction(actionPrefix + ' Delete User Success', props<{ uuid: string }>());

export const deleteUserFailure = createAction(actionPrefix + ' Delete User Failure', props<{ error: Error; uuid: string }>());

/********************************
 *     Resend E-Mail ACTIONS    *
 ********************************/

export const resend = createAction(actionPrefix + ' Resend');

export const resendSuccess = createAction(actionPrefix + ' Resend Success');

export const loadCurrentUserFailure = createAction(actionPrefix + ' Load Current User Failure', props<{ error: any }>());

/********************************
 *         PATCH ACTIONS        *
 ********************************/

export const patchCurrentUser = createAction(actionPrefix + ' Patch Current User', props<{ data: UserUpdate }>());

export const patchCurrentUserSuccess = createAction(actionPrefix + ' Patch Current User Success', props<{ request_id?: string }>());

export const patchCurrentUserFailure = createAction(actionPrefix + ' Patch Current User Failure', props<{ error: Error }>());
export const resendFailure = createAction(actionPrefix + ' Resend Failure', props<{ error: Error }>());

/********************************
 *          PASSWORD ACTIONS    *
 ********************************/

export const loadPasswords = createAction(actionPrefix + ' Load User Passwords');

export const loadPasswordsSuccess = createAction(actionPrefix + ' Load User Passwords Success', props<{ uuid: string; data: User }>());

export const loadPasswordsFailure = createAction(actionPrefix + ' Load User Passwords Failure', props<{ uuid: string; error: any }>());

export const patchPasswords = createAction(actionPrefix + ' Patch User Passwords', props<{ uuid: string; data: any }>());

export const patchPasswordsSuccess = createAction(actionPrefix + ' Patch User Password Success', props<{ request_id?: string }>());

export const patchPasswordsFailure = createAction(actionPrefix + ' Patch User Password Failure', props<{ error: Error }>());

export const postPassword = createAction(actionPrefix + ' Post User Passwords', props<{ data: any }>());
export const postPasswordSuccess = createAction(actionPrefix + ' Post User  Password Success', props<{ request_id?: string }>());
export const postPasswordFailure = createAction(actionPrefix + ' Post User Password Failure', props<{ error: Error }>());

/********************************
 *          OTP ACTIONS    *
 ********************************/

export const loadOtps = createAction(actionPrefix + ' Load User OTPs');

export const loadOtpsSuccess = createAction(actionPrefix + ' Load User OTPs Success', props<{ uuid: string; data: User }>());

export const loadOtpsFailure = createAction(actionPrefix + ' Load User OTPs Failure', props<{ uuid: string; error: any }>());

export const createOTP = createAction(actionPrefix + ' Create User OTP', props<{ data: any }>());

export const createOTPSuccess = createAction(actionPrefix + ' Create User OTP Success', props<{ data: any }>());

export const createOTPFailure = createAction(actionPrefix + ' Create User OTP Failure', props<{ error: Error }>());

export const validateOTP = createAction(actionPrefix + ' Validate User OTP', props<{ token_uuid: string; data: any }>());

export const validateOTPSuccess = createAction(actionPrefix + ' Validate User OTP Success', props<{ token_uuid: string }>());

export const validateOTPFailure = createAction(actionPrefix + ' Validate User OTP Failure', props<{ token_uuid: string }>());

export const patchOTP = createAction(actionPrefix + ' Patch User OTP', props<{ token_uuid: string; data: any }>());

export const patchOTPSuccess = createAction(actionPrefix + ' Patch User OTP Success', props<{ token_uuid: string }>());

export const patchOTPFailure = createAction(actionPrefix + ' Patch User OTP Failure', props<{ token_uuid: string; error: Error }>());

export const deleteOTP = createAction(actionPrefix + ' Delete User OTP', props<{ token_uuid: string }>());

export const deleteOTPSuccess = createAction(actionPrefix + ' Delete User OTP Success', props<{ token_uuid: string }>());

export const deleteOTPailure = createAction(actionPrefix + ' Delete User OTP Failure', props<{ token_uuid: string; error: Error }>());

/********************************
 *          SAML ACTIONS    *
 ********************************/

export const setSamlFromConfig = createAction(actionPrefix + ' Set User Saml config', props<{ data: any }>());

export const loadSamls = createAction(actionPrefix + ' Load User loadSamls');

export const loadSamlsSuccess = createAction(actionPrefix + ' Load User loadSamls Success', props<{ data: User }>());

export const loadSamlsFailure = createAction(actionPrefix + ' Load User loadSamls Failure', props<{ error: any }>());

export const activateSaml = createAction(actionPrefix + ' Activate User SAML', props<{ data: any }>());
export const deactivateSaml = createAction(actionPrefix + ' Deactivate User SAML', props<{ saml_uuid: string }>());

export const updateSamlSuccess = createAction(actionPrefix + ' Update User SAML Success', props<{ saml_uuid: string }>());

export const updateSamlFailure = createAction(actionPrefix + ' Update User SAML Failure', props<{ saml_uuid: string; error: Error }>());

/********************************
 *          Invite ACTIONS    *
 ********************************/

export const loadInvites = createAction(actionPrefix + ' Load Invites');

export const loadInvitesSuccess = createAction(actionPrefix + ' Load Invites Success', props<{ data: any }>());

export const loadInvitesFailure = createAction(actionPrefix + ' Load Invites Failure', props<{ error: any }>());

export const createInviteToken = createAction(actionPrefix + ' Create User Invite', props<{ data: any }>());

export const createInviteTokenSuccess = createAction(actionPrefix + ' Create User Invite Success', props<{ data: any }>());

export const createInviteTokenFailure = createAction(actionPrefix + ' Create User Invite Failure', props<{ error: Error }>());

export const deleteInviteToken = createAction(actionPrefix + ' Delete User Invite', props<{ invite_uuid: any }>());

export const deleteInviteTokenSuccess = createAction(actionPrefix + ' Delete User Invite Success', props<{ invite_uuid: any }>());

export const deleteInviteTokenFailure = createAction(actionPrefix + ' Delete User Invite Failure', props<{ invite_uuid: string; error: Error }>());

/********************************
 *          ROLE ACTIONS    *
 ********************************/

export const patchRole = createAction(actionPrefix + ' Patch User Role', props<{ uuid: string; data: any }>());
export const patchRoleSuccess = createAction(actionPrefix + ' Patch User Role Success', props<{ uuid: string }>());
export const patchRoleFailure = createAction(actionPrefix + ' Patch User Role Failure', props<{ uuid: string; error?: Error }>());

/********************************
 *     AUTOLOGIN SETTINGS       *
 ********************************/

export const getAutoLoginSettings = createAction(actionPrefix + ' Get Autologin Settings for User');

export const getAutoLoginSettingsSuccess = createAction(actionPrefix + ' Get Autologin Settings for User Success', props<{ data: any }>());

export const getAutoLoginSettingsFailure = createAction(actionPrefix + ' Get Autologin Settings for User Failure', props<{ error: Error }>());

export const patchAutoLoginSettings = createAction(actionPrefix + ' Patch Autologin Settings for User', props<{ data: any }>());

export const patchAutoLoginSettingsSuccess = createAction(actionPrefix + ' Patch Autologin Settings for User Success', props<{ data: any }>());

export const patchAutoLoginSettingsFailure = createAction(actionPrefix + ' Patch Autologin Settings for User Failure', props<{ error: Error }>());

export const storeAutoLoginSettings = createAction(actionPrefix + ' Store Autologin Settings for User', props<{ uuid: any }>());
