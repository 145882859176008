import { createAction, props } from '@ngrx/store';
import { Contract, UpdateContract } from './contract.reducer';
export const actionPrefix = '[Contract]';

/********************************
 *          Load ACTIONS        *
 ********************************/

export const loadCurrentContract = createAction(actionPrefix + ' Load Current Contract');

export const loadCurrentContractSuccess = createAction(actionPrefix + ' Load Current Contract Success', props<{ data: Contract }>());

export const loadCurrentContractFailure = createAction(actionPrefix + ' Load Current Contract Failure', props<{ error: any }>());

/********************************
 *          PATCH ACTIONS        *
 ********************************/

export const patchContract = createAction(actionPrefix + ' Patch Contract', props<{ uuid: string; data: UpdateContract; fromBatch?: boolean }>());

export const patchContractSuccess = createAction(actionPrefix + ' Patch Contract Success', props<{ uuid: string }>());

export const patchContractFailure = createAction(actionPrefix + ' Patch Contract Failure', props<{ error: Error; uuid: string }>());


/********************************
 *          CUSTOM ACTIONS        *
 ********************************/
export const finalizeContract = createAction(actionPrefix + ' Finalize Contract', props<{
  first_name: string;
  last_name: string;
  phone_number: string;
  contract_name?: string;
  email: string
}>());

export const finalizeContractSuccess = createAction(actionPrefix + ' Finalize Contract Success');
export const finalizeContractFailure = createAction(actionPrefix + ' Finalize Contract Failure', props<{ error: Error }>());


export const setLastLoginTimestamp = createAction(actionPrefix + ' Set last Login timestamp', props<{ last_login: number }>());
