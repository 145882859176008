import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';
import * as _ from 'lodash';
import { getCurrentUserUuid } from '../session/session.selectors';
import { Meta } from '@gridscale/gsclient-js';
import { selectRouteNestedParam } from '../router/router.selectors';

export const selectUserState = createFeatureSelector<fromUser.State>(fromUser.featureKey);

const { selectIds, selectEntities, selectAll, selectTotal } = fromUser.adapter.getSelectors();

const listSelectors = fromUser.listAdapter.getSelectors((state: any) => state.list);

/**
 * get User
 */
export const getUser = (props: { uuid: string }) =>
  createSelector(selectUserState, (state: fromUser.State) => _.get(selectEntities(state), [props.uuid]));

/**
 * get User last error
 */
export const getUserError = (props: { uuid: string }) =>
  createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['error', props.uuid]));

/**
 * get if User is loading
 */
export const isUserLoading = (props: { uuid: string }) =>
  createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['loading', props.uuid], false));

/**
 * get if User is patching
 */
export const isUserPatching = (props: { uuid: string }) =>
  createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['patching', props.uuid], false));

/**
 * get if User is deleting
 */
export const isUserDeleting = (props: { uuid: string }) =>
  createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['deleting', props.uuid], false));

/**
 * get if User is busy (=loading, patching or deleting === true)
 */
export const isUserBusy = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) =>
  _.get(state, ['loading', props.uuid]) === true || _.get(state, ['patching', props.uuid]) === true || _.get(state, ['deleting', props.uuid]) === true ? true : false
);

/**
 * Get if the current User is Busy (check Session User UUID)
 */
export const isCurrentUserBusy = createSelector(selectUserState, getCurrentUserUuid, (state: fromUser.State, _user_uuid) =>
  _.get(state, ['loading', _user_uuid]) === true || _.get(state, ['patching', _user_uuid]) === true || _.get(state, ['deleting', _user_uuid]) === true ? true : false
);

/**
 * get if User is ready loaded
 */
export const isUserLoaded = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['loaded', props.uuid], false));

/**
 * get current User
 */
export const getCurrentUser = createSelector(selectUserState, getCurrentUserUuid, (state: fromUser.State, _user_uuid) => {
  return _.get(selectEntities(state), [_user_uuid]);
});

/**
 * get current User last error
 */
//export const getCurrentUserError = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['current', 'error']));
export const getCurrentUserError = createSelector(selectUserState, getCurrentUserUuid, (state: fromUser.State, _user_uuid) => _.get(state, ['error', _user_uuid]));

/**
 * get if current User is loading
 */
export const isCurrentUserLoading = createSelector(selectUserState, getCurrentUserUuid, (state: fromUser.State, _user_uuid) => _.get(state, ['loading', _user_uuid], false));

/**
 * get if current User is ready loaded
 */
export const isCurrentUserLoaded = createSelector(selectUserState, getCurrentUserUuid, (state: fromUser.State, _user_uuid) => _.get(state, ['loaded', _user_uuid], false));

/**
 * Check if the current User is Patching
 */
export const isCurrentUserPatching = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['current', 'patching'], false));

/**
 * get current User
 */
export const hasAccessToPartnerPanel = createSelector(selectUserState, getCurrentUserUuid, (state: fromUser.State, _user_uuid) => {
  return _.size(_.get(selectEntities(state), [_user_uuid, 'relations', 'partners'])) > 0;
});

/********************************
 *    Special Case Selectors    *
 ********************************/
 export const shoudCurrentUserSeeTenantPanelOnBoarding = createSelector(selectUserState, getCurrentUserUuid, (state: fromUser.State, _user_uuid) => {
    let isAOldUser = false;
    const user = _.get(selectEntities(state), [_user_uuid]);

    if (user && user.create_time && new Date(user.create_time) < new Date('2022-02-21T00:00:00Z')) {
      isAOldUser = true;
    }

   return isAOldUser;
 });



/**
 * Check if we are Resending
 */
export const isResending = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['ui_states', 'resending'], false));
export const hasResendingError = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['ui_states', 'resending_error'], false));
export const userTotal = createSelector(selectUserState, (state: fromUser.State) => _.size(_.get(state, ['list','ids'], [])));

/********************************
 *             TABLE Actions              *
 ********************************/

export const getUsers = createSelector(selectUserState, (state: fromUser.State) => _.toArray(_.get(state, ['list', 'entities'], [])) as fromUser.User[]);

export const isUsersLoading = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['list', 'loading'], false));

export const isUsersLoaded = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['list', 'loaded'], false));

/**
 * Get Network list meta data (total etc.)
 */
export const getUserstMeta = createSelector(selectUserState, getUsers, (state: fromUser.State, all) => {
  return {
    page: 0,
    count: _.size(all),
    limit: _.size(all),
    offset: 0,
    total: _.size(all)
  } as Meta;
});

/********************************
 *             Selected              *
 ********************************/

/**
 * get selected User (by route param)
 */
export const getSelectedUser = createSelector(selectUserState, selectRouteNestedParam('user_uuid'), (state: fromUser.State, user_uuid) =>
  _.get(selectEntities(state), [user_uuid])
);

export const getSelectedUserFromGETorLIST = createSelector(selectUserState, selectRouteNestedParam('user_uuid'), (state: fromUser.State, user_uuid) =>
  {
    const user = _.get(selectEntities(state), [user_uuid]);
    // check Single GET first!
    if (user) {
      return user
    } else {
     return _.get(state ,['list','entities',user_uuid]);
    }

  }
);

/**
 * get selected User (by route param) last error
 */
export const getSelectedUserError = createSelector(selectUserState, selectRouteNestedParam('user_uuid'), (state: fromUser.State, user_uuid) =>
  _.get(state, ['error', user_uuid])
);

/**
 * get if selected User (by route param) is loading
 */
export const isSelectedUserLoading = createSelector(selectUserState, selectRouteNestedParam('user_uuid'), (state: fromUser.State, user_uuid) =>
  _.get(state, ['loading', user_uuid], false)
);

/**User (by route param) is patching
 */
export const isSelectedUserPatching = createSelector(selectUserState, selectRouteNestedParam('user_uuid'), (state: fromUser.State, user_uuid) =>
  _.get(state, ['patching', user_uuid], false)
);

/**
 * get if selected User (by route param) is deleting
 */
export const isSelectedUserDeleting = createSelector(selectUserState, selectRouteNestedParam('user_uuid'), (state: fromUser.State, user_uuid) =>
  _.get(state, ['deleting', user_uuid], false)
);

/**
 * get if selected User (by route param) is busy (=loading, patching or deleting === true)
 */
export const isSelectedUserBusy = createSelector(selectUserState, selectRouteNestedParam('user_uuid'), (state: fromUser.State, user_uuid) =>
  _.get(state, ['loading', user_uuid]) === true || _.get(state, ['patching', user_uuid]) === true || _.get(state, ['deleting', user_uuid]) === true ? true : false
);

/**
 * get if selected Network (by route param) is ready loaded
 */
export const isSelectedUserLoaded = createSelector(
  selectUserState,
  selectRouteNestedParam('user_uuid'),
  (state: fromUser.State, user_uuid) =>
  _.get(state, ['loaded', user_uuid], false)
);

/********************************
 *             Password              *
 ********************************/

export const isCurrentUserPasswordsLoading = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['passwords', 'loading'], false));

export const isCurrentUserPasswordsLoaded = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['passwords', 'loaded'], false));

export const isCurrentUserPasswordsPatching = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['passwords', 'patching'], false));

export const getCurrentUserPassword = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['passwords', 'data', 0], false));
export const getCurrentUserPasswordUUID = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['passwords', 'data', 0, 'object_uuid'], false));

/********************************
 *             OTP              *
 ********************************/
export const isOTPLoading = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['otptokens', 'loading'], false));
export const isOTPLoaded = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['otptokens', 'loaded'], false));
export const isOTPCreating = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['otptokens', 'creating'], false));
export const isOTPValidating = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['otptokens', 'validating'], false));

export const getOTPs = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['otptokens', 'data', 'data'], {}));
export const getOTPSize = createSelector(selectUserState, (state: fromUser.State) => _.size(_.get(state, ['otptokens', 'data', 'data'], {})));
export const getOTPByUUID = (props: { uuid: string })=> createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['otptokens', 'data', 'data', props.uuid], false));
export const isSingleOTPLoading = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) =>
  _.get(state, ['otptokens', 'data', 'loading', props.uuid], false)
);
export const isSingleOTPLoaded = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) =>
  _.get(state, ['otptokens', 'data', 'loaded', props.uuid], false)
);
export const isSingleOTPPatching = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) =>
  _.get(state, ['otptokens', 'data', 'patching', props.uuid], false)
);
export const isSingleOTPDeleting = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) =>
  _.get(state, ['otptokens', 'data', 'deleting', props.uuid], false)
);

/********************************
 *             SAML              *
 ********************************/
export const isSamlsLoading = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['samls', 'loading'], false));
export const isSamlsLoaded = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['samls', 'loaded'], false));

export const getAvailibleSamls = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['samls', 'configs'], false));
export const getAvailibleSamlsSize = createSelector(selectUserState, (state: fromUser.State) => _.size(_.get(state, ['samls', 'configs'], {})));

export const getSamls = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['samls', 'data', 'data'], false));
export const getSamlsSize = createSelector(selectUserState, (state: fromUser.State) => _.size(_.get(state, ['samls', 'data', 'data'], {})));
export const getSamlByUUID = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['samls', 'data', 'data', props.uuid], false));
export const isSamlActive = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) => _.has(state, ['samls', 'data', 'data', props.uuid]));
export const isSingleSamlLoading = (props: { uuid: string }) => createSelector(selectUserState, (state: fromUser.State) =>
  _.get(state, ['samls', 'data', 'loading', props.uuid], false)
);

/********************************
 *             INVITES              *
 ********************************/
export const isInvitesLoading = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['invites', 'loading'], false));
export const isInvitesLoaded = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['invites', 'loaded'], false));

export const getInvites = createSelector(selectUserState, (state: fromUser.State) => _.toArray(_.get(state, ['invites', 'data', 'data'])));
export const getInvitesSize = createSelector(selectUserState, getInvites, (state: fromUser.State, all) => _.size(all));

export const getUnclaimedInvites = createSelector(selectUserState, (state: fromUser.State) =>
  _.filter(_.toArray(_.get(state, ['invites', 'data', 'data'])), _item => !_item.claimant_email)
);
export const getUnclaimedInvitesSize = createSelector(selectUserState, getUnclaimedInvites, (state: fromUser.State, all) => _.size(all));

/**
 * get selected User (by route param)
 */
 export const getSelectedInvite = createSelector(selectUserState, selectRouteNestedParam('invite_uuid'), (state: fromUser.State, invite_uuid) =>
 _.get(state, ['invites', 'data', 'data',invite_uuid], undefined)
);


/********************************
 *         AUTO Login           *
 ********************************/
 export const isAutoLoginLoading = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['auto_login_settings', 'loading'], false));
 export const isAutoLoginLoaded = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['auto_login_settings', 'loaded'], false));
 export const getAutoLoginOptions = createSelector(selectUserState, (state: fromUser.State) => _.toArray(_.get(state, ['auto_login_settings', 'auto_login_options'])));
 export const getAutoLogin = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['auto_login_settings']));
 export const getCurrentAutoLoginOption = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['auto_login_settings', 'selected_auto_login_option_uuid'], null));
 export const isAutoLoginEnabled = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['auto_login_settings', 'auto_login_active'],false));
 export const isAutoLoginPatching = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['auto_login_settings', 'patching'],false));
 export const getStoredAutoLogin = createSelector(selectUserState, (state: fromUser.State) => _.get(state, ['auto_login_settings', 'uuid_to_store_later'],null));


