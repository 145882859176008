import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import * as ContractActions from './contract.actions';

export interface Contract {
  object_uuid?: string;
  name?: string;
  last_billing_time?: string;
  disconnected?: boolean;
  create_time?: string;
  change_time?: string;
  payment_enabled?: boolean;
  first_payment_activated?: string;
  locked?: boolean;
  template_prices_uuid?: string;
  template_prices_name?: string;
  template_feature_flags_uuid?: string;
  template_feature_flags_name?: string;
  template_resource_limits_uuid?: string;
  template_resource_limits_name?: string;
  default_location_uuid?: string;
  favorite?: boolean;
  wait_for_sepa_valid?: boolean;
  manual_lock?: boolean;
  resource_limits?: {
    [limitKey: string]: number;
  };
  feature_flags?: {
    [featureKey: string]: boolean;
  };
  prices?: {
    [priceKey: number]: {
      name?: string;
      price_per_unit: number;
      unit: string;
      type: string;
      currency: string;
      productNo: number;
    };
  };
  relations?: {
    projects: {
      name: string;
      object_uuid: string;
      contract_uuid: string;
      partner_uuid: string;
      change_time: string;
      create_time: string;
      deleted: boolean;
      location_country: string;
      location_iata: string;
      location_name: string;
      location_site: string;
      location_uuid: string;
    }[];
  };
}

export interface UpdateContract {
  name?: string;
}

export const featureKey = 'contract';

export interface State {
  loading: { [key: string]: boolean };
  loaded: { [key: string]: boolean };
  patching: { [key: string]: boolean };
  deleting: { [key: string]: boolean };
  error?: { [key: string]: Error | HttpErrorResponse | undefined };
  current: {
    loading: boolean;
    loaded: boolean;
    error?: Error | HttpErrorResponse | undefined;
    data?: Contract;
  };
  currentFinalizing: boolean;
  list: {
    loading: boolean;
    loaded: boolean;
    error?: Error | HttpErrorResponse | undefined;
    data?: Contract[];
  };
  last_login?: number;
}
export const initialState: State = {
  loading: {},
  loaded: {},
  patching: {},
  deleting: {},
  current: {
    loading: false,
    loaded: false,
    error: undefined,
    data: undefined
  },
  currentFinalizing: false,
  list: {
    loading: false,
    loaded: false,
    error: undefined,
    data: undefined
  }
};

export const reducer = createReducer(
  initialState,

  on(ContractActions.loadCurrentContract, (state, action) => ({
    ...state,
    current: {
      ...state.current,
      loading: true,
      loaded: false
    }
  })),

  on(ContractActions.loadCurrentContractSuccess, (state, action) => ({
    ...state,
    current: {
      ...state.current,
      error: undefined,
      loading: false,
      loaded: true,
      data: action.data
    }
  })),

  on(ContractActions.loadCurrentContractFailure, (state, action) => ({
    ...state,
    current: {
      ...state.current,
      error: action.error,
      loading: false,
      loaded: true
    }
  })),

  on(ContractActions.patchContract, (state, action) => ({
    ...state,
    patching: { ...state.patching, [action.uuid]: true },
    error: { ...state.error, [action.uuid]: undefined }
  })),

  on(ContractActions.patchContractSuccess, (state, action) => ({
    ...state,
    current: {
      ...state.current,
      loading: true
    },
    patching: { ...state.patching, [action.uuid]: false },
    error: { ...state.error, [action.uuid]: undefined }
  })),
  on(ContractActions.patchContractFailure, (state, action) => ({
    ...state,

    patching: { ...state.patching, [action.uuid]: false },
    error: { ...state.error, [action.uuid]: action.error }
  })),
  on(ContractActions.finalizeContract, (state) => ({
    ...state,
    currentFinalizing: true
  })),
  on(ContractActions.finalizeContractSuccess, (state) => ({
    ...state,
    currentFinalizing: false
  })),
  on(ContractActions.finalizeContractFailure, (state) => ({
    ...state,
    currentFinalizing: false
  })),
  on(ContractActions.setLastLoginTimestamp, (state, action) => ({
    ...state,
    last_login: action.last_login
  }))
);
