import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { CustomState } from './router.reducer';
import * as _ from 'lodash';
import { Data, Params } from '@angular/router';

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<any>>('router');

export const selectRouterCustom = createFeatureSelector<CustomState>('routerCustom');

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

/**
 * get the last url
 */
export const getLastUrl = createSelector(selectRouterCustom, (state: CustomState) => _.get(state, ['urlLast'], ''));

/**
 * get the last distinct component urls (explanation see reducer)
 */
//  export const getLastDistinctComponentUrls = createSelector(selectRouterCustom, (state: CustomState) => _.get(state, ['urlsLastDistinctComponent'], ''));

/**
 * get the entry url
 */
export const getEntryUrl = createSelector(selectRouterCustom, (state: CustomState) => _.get(state, ['urlEntry'], ''));

/**
 * get the entry url
 */
export const getNavigationCount = createSelector(selectRouterCustom, (state: CustomState) => _.get(state, ['navigationCount'], 1));

/**
 * select the route params from all nodes (not only the leaf node)
 * @see https://ngrx.io/guide/router-store/selectors
 */
export const selectRouteNestedParams = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});


export const selectRouteNestedData = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;

  let data: Data = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    data = {
      ...data,
      ...currentRoute.data,
    };
  }
  return data;
});

export const getLastDetailViewUrl = createSelector(selectRouterCustom, (state: CustomState) => {
  if (!(state?.detailViewUrls?.length > 0)) {
    return undefined;
  }
  return state.detailViewUrls[state.detailViewUrls.length - 1];
});

export const hasLastDetailViewUrls = createSelector(selectRouterCustom, (state: CustomState) => {
  return state?.detailViewUrls?.length > 0;
});

export const getDetailViewUrlCount = createSelector(selectRouterCustom, (state: CustomState) => {
  return _.get(state, 'detailViewUrls.length', 0);
});

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params && params[param]);
