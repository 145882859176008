import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromContract from './contract.reducer';
import * as UserSelector from './../user/user.selectors';
import * as _ from 'lodash';
import { User } from '../user/user.reducer';

export const selectContractState = createFeatureSelector<fromContract.State>(fromContract.featureKey);

/**
 * get current Contract
 */
export const getCurrentContract = createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['current', 'data']));

/**
 * get current Contract
 */
export const getCurrentContractUuid = createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['current', 'data', 'object_uuid']));
export const getCurrentContractName = createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['current', 'data', 'name']));

/**
 * get current Contract last error
 */
export const getCurrentContractError = createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['current', 'error']));

/**
 * get if current Contract is loading
 */
export const isCurrentContractLoading = createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['current', 'loading'], false));

/**
 * get if current Contract is busy (=loading, patching or deleting === true)
 */
export const isCurrentContractBusy = createSelector(selectContractState, (state: fromContract.State) =>
  _.get(state, ['current', 'loading']) === true || _.get(state, ['current', 'patching']) === true || _.get(state, ['current', 'deleting']) === true ? true : false
);

/**
 * get if current Contract is ready loaded
 */
export const isCurrentContractLoaded = createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['current', 'loaded'], false));

/**
 * get current Contract
 */
export const hasDirectAccessToContract = createSelector(getCurrentContractUuid, UserSelector.getCurrentUser, (contract_uuid, _current_user: User) => {
  if (contract_uuid && _current_user && _current_user.relations) {
    return !!_.find(_current_user.relations.contracts, {
      contract_uuid: contract_uuid
    });
  } else {
    return false;
  }
});

export const getDefaultLocationUuid = createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['current', 'data', 'default_location_uuid']));

export const isContractPatching = (props: { uuid: string }) => createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['patching', props.uuid], false));
export const isContractLoading = (props: { uuid: string }) => createSelector(selectContractState, (state: fromContract.State) => _.get(state, ['loading', props.uuid], false));

export const isCurrentFinalizing = createSelector(selectContractState, (state: fromContract.State) => _.get(state, 'currentFinalizing', false));

export const getLastLogin = createSelector(selectContractState, (state: fromContract.State) => _.get(state, 'last_login', 0));
